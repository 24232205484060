*,
::after,
::before {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* playfair-display-regular - latin */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/playfair-display-v30-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/playfair-display-v30-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/playfair-display-v30-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/playfair-display-v30-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/playfair-display-v30-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/playfair-display-v30-latin-regular.svg#PlayfairDisplay")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Source Code Pro";
  src: url("../fonts/SourceCodePro-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Source Code Pro Regular";
  src: url("../fonts/SourceCodePro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins Thin";
  src: url("../fonts/Poppins-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins Medium";
  src: url("../fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter Light";
  src: url("../fonts/Inter-Light.ttf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Source Code Pro";
}

.MuiImageListItemBar-subtitle {
  font-weight: 600;
}

.hero-image {
  display: flex;
  flex-direction: column;
  background-image: url("../../public/main-page/main-1.png");
  align-self: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  padding: 0px !important;
  height: auto !important;
}

.hero-image-right {
  background-image: url("../../public/main-page/hero-right.png");
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: auto;
  margin-right: auto;
}

.hero-step-1 {
  background-image: url("../../public/main-page/main-step-1-image.png");
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  float: right;
  margin-right: 1em;
  width: inherit;
}

.hero-step-2 {
  background-image: url("../../public/main-page/step-2-image.png");
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  float: left;
  margin-left: 1em;
  width: inherit;
}

.hero-step-3 {
  background-image: url("../../public/main-page/step-3-image.png");
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  float: right;
  margin-left: 1em;
  width: inherit;
}

.hero-step-1-number {
  background-image: url("../../public/main-page/main-page-step-1-number.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 2em;
  height: 2em;
  display: inline-block;
  margin-right: 0.5em;
}

.hero-step-2-number {
  background-image: url("../../public/main-page/step-2-number.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 2em;
  height: 2em;
  display: inline-block;
  margin-right: 0.5em;
}

.hero-step-3-number {
  background-image: url("../../public/main-page/step-3-number.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 2em;
  height: 2em;
  display: inline-block;
  margin-right: 0.5em;
}

.quotation-mark-right {
  background-image: url("../../public/common/quotation-right-mark.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
  margin-right: 0.5em;
}

.footer-box {
  background-color: #2c3441;
}

.footer-logo {
  background-image: url("../../public/common/footer-logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: inherit;
  width: 1.75em;
  min-height: 1em;
  margin-left: auto;
  margin-right: auto;
}

.path-trail {
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.order-status-box {
  display: inline-block;
  font-weight: 700;
  padding: 5px 10px 5px 10px;
  border-radius: 25px;
  min-width: 10em;
}

.orders-total-box {
  display: inline-block;
  float: right;
  margin: 10px;
  padding: 10px 15px 10px 15px;
  background-color: rgb(55, 59, 99, 0.3);
  border-radius: 25px;
}

.faq-row {
  border: none !important;
}

.home-page .hero-image-right {
  background-position: center;
}
.onlymob {
  display: none;
}
.home-page,
header .MuiToolbar-root,
.footer-box .MuiGrid-container {
  width: 1200px !important;
  margin: 0px auto !important;
  padding: 0px 20px;
  max-width: 100% !important;
}
.home-page .hero-image-top {
  display: flex;
  align-items: center;
}
.home-top-sec {
  min-height: 70vh;
}
.home-page .mobiletitle {
  margin-bottom: 33px;
}
@media screen and (max-width: 817px) {
  .home-top-sec {
    min-height: auto;
  }
  .home-page {
    padding: 0px 0px;
  }
  .onlydesk {
    display: none;
  }
  .onlymob {
    display: block;
  }
  .home-page .hero-image-top {
    height: auto !important;
    padding-right: 0px !important;
    width: auto !important;
  }
  .home-page .css-nk32ej .home-page,
  .MuiGrid-root {
    justify-content: center !important;
    margin: 0px !important;
  }
  .nopadmob {
    padding: 0px !important;
  }

  .hero-image {
    padding: 0px 20px !important;
    padding-top: 25px !important;
  }
  .css-480o17-MuiGrid-root {
    display: block !important;
    width: 100% !important;
    max-width: 100% !important;
    margin: 0px !important;
  }
  .css-1nv1o5k-MuiGrid-root {
    max-width: 100% !important;
    margin-top: 19px !important;
  }
  .css-zow5z4-MuiGrid-root {
    display: block !important;
    width: 100% !important;
    max-width: 100%;
    margin: 0px !important;
    text-align: center;
    padding-bottom: 41px !important;
  }
  .css-b8j6au-MuiGrid-root {
    max-width: 100% !important;
  }
  .footer-logo {
    margin-top: 14px;
  }
  .home-page .hero-step-1,
  .home-page .hero-step-2,
  .home-page .hero-step-3 {
    float: none;
    margin: 0px auto;
    margin-top: 34px;
    background-position: center;
  }
  .home-page .rightmobile {
    justify-content: center !important;
    text-align: center;
  }
  .home-page .mobiletitle {
    font-size: 28px !important;
    margin-bottom: 33px;
  }

  .home-page .quotation-mark-right {
    margin-top: 0px !important;
  }
  header .MuiToolbar-root {
    padding: 0px !important;
  }
}
@media screen and (max-width: 525px) {
  .toptitle {
    font-size: 35px !important;
  }
}
