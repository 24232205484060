.loading-top-text {
  font-size: 2em;
  font-family: "Poppins Thin";
  font-weight: 700;
}

.loading-bottom-text {
  font-size: 2.25em;
  font-family: "Poppins Medium";
  font-weight: 400;
}
