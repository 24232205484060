@media screen and (max-width: 817px) {
  .home-top-sec {
    min-height: auto;
  }
  .home-page {
    padding: 0px 0px;
  }
  .onlydesk {
    display: none;
  }
  .onlymob {
    display: block;
  }
  .home-page .hero-image-top {
    height: auto !important;
    padding-right: 0px !important;
    width: auto !important;
  }
  .home-page .css-nk32ej .home-page,
  .MuiGrid-root {
    justify-content: center !important;
    margin: 0px !important;
  }
  .nopadmob {
    padding: 0px !important;
  }

  .hero-image {
    padding: 0px 20px !important;
    padding-top: 25px !important;
  }
  .css-480o17-MuiGrid-root {
    display: block !important;
    width: 100% !important;
    max-width: 100% !important;
    margin: 0px !important;
  }
  .css-1nv1o5k-MuiGrid-root {
    max-width: 100% !important;
    margin-top: 19px !important;
  }
  .css-zow5z4-MuiGrid-root {
    display: block !important;
    width: 100% !important;
    max-width: 100%;
    margin: 0px !important;
    text-align: center;
    padding-bottom: 41px !important;
  }
  .css-b8j6au-MuiGrid-root {
    max-width: 100% !important;
  }
  .footer-logo {
    margin-top: 14px;
  }
  .home-page .hero-step-1,
  .home-page .hero-step-2,
  .home-page .hero-step-3 {
    float: none;
    margin: 0px auto;
    margin-top: 34px;
    background-position: center;
  }
  .home-page .rightmobile {
    justify-content: center !important;
    text-align: center;
  }
  .home-page .mobiletitle {
    font-size: 28px !important;
    margin-bottom: 33px;
  }

  .home-page .quotation-mark-right {
    margin-top: 0px !important;
  }
  header .MuiToolbar-root {
    padding: 0px !important;
  }
  .medium-text-align-center {
    text-align: center;
  }
  .create-order-border {
    border-left: none;
  }
  .hero-image-right {
    margin-top: 2em;
  }
}
@media screen and (max-width: 525px) {
  .toptitle {
    font-size: 35px !important;
  }
  .no-mobile-view {
    display: none;
  }
  .product-box-mobile-view {
    margin-bottom: 1em;
  }
  .product-choice-outer-box.large {
    width: 17em;
    height: 17em;
  }
  .product-choice-inner-box.large {
    width: 15.6em;
    height: 15.6em;
    border-radius: 18%;
  }
  .preview-order-country-text {
    text-align: center;
  }
  .price-usd-warning {
    width: 100%;
  }

  .centered-order-stage-content.large-small {
    width: 85%;
  }
  .review-order-order-detail-blocks {
    min-height: 7em;
  }
  .create-order-image.large {
    width: 17.5em;
    height: 17.5em;
  }
  .img-border.large {
    width: 19em;
    height: 19em;
  }
}
