.centered-full-stage-content {
  max-width: 2400px;
  margin-left: auto;
  margin-right: auto;
}

.product-choice-outer-box {
  background-image: url("../../public/common/img-border.png");
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13em;
  height: 13em;
}

.product-choice-outer-box.active {
  background-image: url("../../public/common/img-border-active.png");
}

.product-choice-inner-box.small {
  width: 11em;
  height: 11em;
  border-radius: 18%;
}

.product-choice-inner-box.medium {
  width: 15.6em;
  height: 15.6em;
  border-radius: 18%;
}

.product-choice-inner-box.large {
  width: 23.3em;
  height: 23.3em;
  border-radius: 18%;
}

.icon-creation {
  width: 10em;
  height: 10em;
  background-repeat: no-repeat;
  background-size: contain;
}
.icon-creation.mug {
  background-image: url("../../public/main-page/iconic-creations/custom-dalle-mug-cat-and-rapper.png");
}
.icon-creation.notebook {
  width: 7.5em;
  height: 7.5em;
  background-image: url("../../public/main-page/iconic-creations/custom-dalle-notebook-johnny-bravo.png");
}
.icon-creation.card {
  width: 13em;
  height: 13em;
  margin-bottom: 1em;
  background-image: url("../../public/main-page/iconic-creations/custom-dalle-greeting-card-postal-worker.png");
}
.icon-creation.coaster {
  background-image: url("../../public/main-page/iconic-creations/custom-dalle-coaster-dog-tricycle.png");
}
.icon-creation.mask {
  width: 7.5em;
  height: 7.5em;
  background-image: url("../../public/main-page/iconic-creations/custom-dalle-facemask-bojack-spiderman.png");
}
.icon-creation.sticker {
  background-image: url("../../public/main-page/iconic-creations/custom-dalle-sticker-bojack-budapest.png");
}
.icon-creation.frame {
  background-image: url("../../public/main-page/iconic-creations/custom-dalle-frame-rapper.png");
}

.preview-left-box {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-choice-outer-box.small {
  width: 12em;
  height: 12em;
}

.product-choice-outer-box.medium {
  width: 17em;
  height: 17em;
}

.product-choice-outer-box.large {
  width: 25em;
  height: 25em;
}

.product-choice-outer-box.mini {
  width: 5.3em;
  height: 5.3em;
}

.product-choice-inner-box.mini {
  width: 5em;
  height: 5em;
  border-radius: 18%;
}

.product-choice-name {
  text-align: center;
  width: 13em;
  margin-top: 0.75em;
}

.product-choice-dashboard-box {
  background-color: #cccccc9f;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}

.product-choice-inner-box {
  background-color: #cccccc8f;
  border-radius: 25px;
  width: 11.6em;
  height: 11.6em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-choice-sticker {
  background-image: url("../../public/products/sticker.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 8em;
  height: 8em;
}

.product-choice-frame {
  background-image: url("../../public/products/frame.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 9.5em;
  height: 9.5em;
}

.product-choice-mug {
  background-image: url("../../public/products/mug.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 9.5em;
  height: 9.5em;
}

.product-choice-greeting-card {
  background-image: url("../../public/products/greeting-card.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 8em;
  height: 8em;
}

.product-choice-notebook {
  background-image: url("../../public/products/notebook.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 8em;
  height: 8em;
}

.product-choice-facemask {
  background-image: url("../../public/products/facemask.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 8em;
  height: 8em;
}

.product-choice-mousepad {
  background-image: url("../../public/products/mousepad.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 8em;
  height: 8em;
}

.product-choice-coaster {
  background-image: url("../../public/products/coaster.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 8em;
  height: 8em;
}

.centered-order-stage-content {
  width: 70%;
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
}

.img-border {
  background-image: url("../../public/common/img-border.png");
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-border.active {
  background-image: url("../../public/common/img-border-active.png");
}

.img-border.medium {
  width: 15em;
  height: 15em;
}

.img-border.mockup {
  width: 30em;
  height: 30em;
}

.img-border-dynamic-inner {
  height: 93%;
  width: 93%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-border-dynamic-inner.preview-page {
  height: 90%;
  width: 90%;
  background-color: #cccccc47;
  border-radius: 50px;
}

.img-border.large {
  width: 27em;
  height: 27em;
}

.dashboard-order-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-product-image-container {
  background-color: #cccccc96;
  width: 60px;
  height: 60px;
  border-radius: 7px;
}

.create-order-border {
  border-left: 1px solid #ccc;
}

.create-order-image {
  background-image: url("../../public/create-order/right-img.png");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50px;
}

.create-order-image.large {
  width: 24.6em;
  height: 24.6em;
}

.create-order-example {
  background-repeat: no-repeat;
  background-size: contain;
  height: 10em;
  width: 10em;
}

.create-order-example.greeting-card-example {
  background-image: url("../../public/create-order/greeting-card-example.png");
}

.create-order-example.sticker-example {
  background-image: url("../../public/create-order/sticker-example.png");
}

.progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 3em;
  padding-top: 3em;
}

.progress-bar-stage {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.149));
}

.progress-bar-stage-inner {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background-color: #ccc;
}

.progress-bar-stage-inner.active {
  background-color: #5895fe;
}

.progress-bar-stage-text {
  position: absolute;
  text-align: center;
  margin-top: 5.5em;
}

.progress-bar-stage-text.active {
  color: #5895fe;
  font-weight: 700;
}

.MuiLoadingButton-loadingIndicator {
  color: white !important;
}

.white-loading-button .MuiLoadingButton-loadingIndicator {
  color: black !important;
}

.progress-bar-stage-connector {
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  background-color: #ccc;
}

.progress-bar-stage-connector.active {
  background-color: #5895fe;
}

.order-stage-box {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.dashboard-image-container {
  padding: 0.5em;
}

.choose-product-icon-inner-circle {
  background-color: rgb(44, 52, 65);
  border-radius: 50%;
  display: flex;
  width: 4em;
  height: 4em;
  justify-content: center;
  align-items: center;
}

.choose-product-outer-circle {
  width: 5em;
  height: 5em;
  border: 0.4em solid #2c34418c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.prompt-image-box {
  display: flex;
  justify-content: center;
}

.prompt-text {
  font-size: 1.2em;
  font-weight: 600;
  max-width: 70%;
  text-align: center;
}

.preview-order-name {
  font-size: 1.5em;
  font-family: "Poppins Medium";
}

.preview-order-country-text {
  font-family: "Poppins Thin";
  padding: 10px;
  font-size: 1.3em;
  line-height: 2;
}

.choose-country-modal {
  min-height: 25em;
}

.price-usd-warning {
  display: inline-block;
  width: 70%;
  font-size: 0.75em;
  font-style: italic;
  text-align: center;
  margin-top: 1em;
}

.Mui-checked {
  color: #5895fe !important;
}

.review-order-right-section {
  width: 100%;
}

.review-order-right-section.top {
  margin-top: 2em;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.review-order-right-section.bottom {
  margin-top: 2em;
  border-bottom: 1px solid #ccc;
  padding-bottom: 2em;
}

.review-order-inner-section-title {
  font-family: "Poppins Medium";
  font-size: 1.3em;
}

.review-order-inner-section-subtitle {
  font-family: "Poppins";
  font-size: 1em;
}

.review-order-order-detail-blocks {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 10em;
}
